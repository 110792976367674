import React, { Component } from "react";
import { connect } from "react-redux";
import { Tab, Row, Col, Nav } from "react-bootstrap";
import { Link } from "@reach/router";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import { ButtonSecondary } from "../../UI/Buttons";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import style from "./License.module.scss";
import ServiceInfo from "../../services/services-drops/ServiceInfo";
import ServiceDealingType from "../../services/ServiceDealingType";
import ServiceDetail from "../../services/services-drops/ServiceDetail";
import ServiceStep from "../../services/ServiceStep";
import { translationHook } from "../../translationHook";
import { getServiceDropdwnInfo } from "../../../apis/services/dropdowns/getServicedropdownInfo";

import CompanyForm from "./CompanyForm";
import LicenseVisas from "./LicenseVisas";
import LicenseRisk from "./LicenseRisk";
import InvestorsList from "./InvestorsList";
import CompanyLicensesList from "./CompanyLicensesList";
import CompanyLegalFilesList from "./CompanyLegalFilesList";
class CompanyInfo extends Component {
	state = {
		key: 0,
		next: 1,
		prev: -1,
		dropdownId: null,
		first: false,
		second: false,
		third: false,
		fourth: false,
		edit: false,
		view: false,
		serviceInfoInit: {
			dropDowmeStatus: true,
		},
	};

	handleNext = () => {
		this.setState({
			key: this.state.next,
			next: this.state.next + 1,
			prev: this.state.prev + 1,
		});
	};

	handlePrev = () => {
		this.setState({
			key: this.state.prev,
			next: this.state.next - 1,
			prev: this.state.prev - 1,
		});
	};

	handleFirstStepDone = (sID, values) => {
		this.setState({
			dropdownId: sID,
			second: false,
			edit: true,
			serviceInfoInit: values,
		});
		this.handleNext();
	};

	renderChevs = () => {
		let rtl = this.props.i18n.language === "ar" ? true : false,
			iconPrev = rtl ? <ChevronRightIcon /> : <ChevronLeftIcon />,
			iconNext = rtl ? <ChevronLeftIcon /> : <ChevronRightIcon />;

		return (
			<>
				<IconButton
					aria-label="prev"
					color="primary"
					onClick={this.handlePrev}
					disabled={this.state.key > 0 ? false : true}
				>
					{iconPrev}
				</IconButton>
				<IconButton
					aria-label="next"
					color="primary"
					onClick={this.handleNext}
					disabled={
						this.state.key < 1 && !this.state.second ? false : true
					}
				>
					{iconNext}
				</IconButton>
			</>
		);
	};

	componentDidMount() {
		if (this.props.location?.state?.tap) {
			this.setState({ key: this.props.location.state.tap });
		}

		// if edit service and there is dropdownId prop in the url
		if (this.props.dropdownId) {
			(async () => {
				let at = this.props.currentUser.Data.access_token,
					dropdownId = this.props.dropdownId,
					values = {};

				const response = await getServiceDropdwnInfo(at, dropdownId);

				if (response) {
					values.dropDownid = response.Service_Lookup_Id;
					values.dropDownNameAR = response.Service_Lookup_Name_AR;
					values.dropDownNameEN = response.Service_Lookup_Name_EN;
					values.dropDowmeStatus =
						response.Active_Status_Id == 1 ? true : false;
					values.Service_Relation_Lookup_Id =
						response.Service_Relation_Lookup_Id;
					this.setState({
						dropdownId: this.props.dropdownId,
						second: false,
						edit:
							this.props.location.hash === "#view" ? false : true,
						view:
							this.props.location.hash === "#view" ? true : false,
						serviceInfoInit: values,
					});
				}
			})();
		}
	}

	render() {
		let mystyle;
		if (this.props.i18n.language === "ar") {
			mystyle = "ServiceTabsAr";
		} else {
			mystyle = "ServiceTabsEn";
		}

		return (
			<Tab.Container
				id="left-tabs-example"
				defaultActiveKey={0}
				activeKey={this.state.key}
				onSelect={(k) =>
					this.setState({
						key: parseInt(k),
						next: parseInt(k) + 1,
						prev: parseInt(k) - 1,
					})
				}
				unmountOnExit="true"
			>
				<Row>
					<Col
						sm={12}
						className="d-flex justify-content-between align-items-center"
					>
						<div>{this.renderChevs()}</div>
						<Nav variant="pills" className={style.stepsNav}>
							<Nav.Item className={mystyle}>
								<Nav.Link
									className={`${style.navLink} ${
										this.state.key === 0 ? style.active : ""
									}`}
									eventKey="0"
									disabled={this.state.first}
								>
									{this.props.t("companyDetails")}
								</Nav.Link>
							</Nav.Item>
							<Nav.Item className={mystyle}>
								<Nav.Link
									className={`${style.navLink} ${
										this.state.key === 1 ? style.active : ""
									}`}
									eventKey="1"
									disabled={this.state.second}
								>
									{this.props.t("companyLicenses")}
								</Nav.Link>
							</Nav.Item>
							<Nav.Item className={mystyle}>
								<Nav.Link
									className={`${style.navLink} ${
										this.state.key === 2 ? style.active : ""
									}`}
									eventKey="2"
									disabled={this.state.third}
								>
									{this.props.t("investorsList")}
								</Nav.Link>
							</Nav.Item>
							<Nav.Item className={mystyle}>
								<Nav.Link
									className={`${style.navLink} ${
										this.state.key === 3 ? style.active : ""
									}`}
									eventKey="3"
									disabled={this.state.fourth}
								>
									{this.props.t("companyLegalFilesList")}
								</Nav.Link>
							</Nav.Item>
						</Nav>
						<ButtonSecondary
							variant="outlined"
							endIcon={<ExitToAppIcon />}
							component={Link}
							to="/manage-companies"
							size="large"
						>
							{this.props.t("exitToCompanieslist")}
						</ButtonSecondary>
					</Col>
					<Col sm={12}>
						<Tab.Content>
							<Tab.Pane eventKey={0} disabled={this.state.first}>
								<CompanyForm companyId={this.props.companyId} />
							</Tab.Pane>
							<Tab.Pane
								eventKey={1}
								disabled={this.state.second}
								// unmountOnExit="true"
							>
								<CompanyLicensesList
									companyId={this.props.companyId}
								/>
							</Tab.Pane>
							<Tab.Pane
								eventKey={2}
								disabled={this.state.third}
								// unmountOnExit="true"
							>
								<InvestorsList
									companyId={this.props.companyId}
								/>
							</Tab.Pane>
							<Tab.Pane
								eventKey={3}
								disabled={this.state.fourth}
								// unmountOnExit="true"
							>
								<CompanyLegalFilesList
									companyId={this.props.companyId}
								/>
							</Tab.Pane>
						</Tab.Content>
					</Col>
				</Row>
			</Tab.Container>
		);
	}
}

const mapStateToProps = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToProps)(translationHook(CompanyInfo));
