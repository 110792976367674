import React, { Component } from "react";
import { connect } from "react-redux";
import { translationHook } from "../../translationHook";
import { Row, Col } from "react-bootstrap";
import { Form, Field } from "react-final-form";
import { TextField, Select, Switches, Autocomplete } from "mui-rff";
import DateBox from "devextreme-react/date-box";
import { Link, navigate } from "@reach/router";
import moment from "moment";
import { PatternFormat } from "react-number-format";
import apiConfig from "../../../apis/apiConfig";
import {
	triggerDialog,
	triggerDialogForm,
	setLoading,
	triggerNotification,
} from "../../../actions";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { ButtonSecondary, ButtonPrimary, ButtonDanger } from "../../UI/Buttons";
import { downloadAttachment } from "./../../../apis/system/downloadAttachment";
import { deleteAttachment } from "./../../../apis/system/deleteAttachment";
import FilesUpload from "../../UI/FileUpload/UploadFile";

class InvestorInfo extends Component {
	state = {
		initValues: {},
		licenseInvestorsList: [],
		visaTypesList: [],
		investorsPrefixsList: [],
		licenseSignaturesList: [],
		countriesList: [],
		nationalyList: [],
		emiratesList: [],
		investorsDataValidation: {},
		investorsSystemValidation: {},
		investorsDetails: {},
		emiratesIDFile: [],
		emiratesIDFileCode: null,
		emiratesIDFile2: [],
		emiratesIDFile2Code: null,
		investorSignatureFile: [],
		investorSignatureFileCode: null,
		passportFile: [],
		passportFileCode: null,
		visaFile: [],
		visaFileCode: null,
		dataLoaded: false,
		partnerRatio: null,
		Btns: [],
	};

	getInvestorDetails = async () => {
		let auth = this.props.currentUser.Data.access_token;
		const response = await apiConfig.post(
			"/API/ADMINISTRATION/LICENSES/COMPANIES/INVESTORS/Get_Investor_Details",
			{
				Company_Id: this?.props?.companyId,
				License_Investor_Data_Id: this?.props?.licenseInvestorDataId,
			},
			{
				headers: {
					Authorization: `Bearer ${auth}`,
				},
			}
		);

		if (response?.data?.Status) {
			console.log(
				response?.data?.Data?.Investor_Details
					?.License_Signature_Type_Id
			);

			let investorsDataValidation =
					response?.data?.Data?.Investor_Data_Validation,
				investorsSystemValidation =
					response?.data?.Data?.Investor_System_Validation,
				investorsDetails = response?.data?.Data?.Investor_Details;

			//add delete btn to files if file exist
			if (
				investorsDetails?.EmiratesID_File?.File_Code &&
				response.data?.Data?.Btn_Save
			) {
				investorsDetails.EmiratesID_File["Can_Delete"] = 1;
			}

			if (
				investorsDetails?.EmiratesID_File_2?.File_Code &&
				response.data?.Data?.Btn_Save
			) {
				investorsDetails.EmiratesID_File_2["Can_Delete"] = 1;
			}

			if (
				investorsDetails?.Passport_File?.File_Code &&
				response.data?.Data?.Btn_Save
			) {
				investorsDetails.Passport_File["Can_Delete"] = 1;
			}

			if (
				investorsDetails?.Investor_Signature_File?.File_Code &&
				response.data?.Data?.Btn_Save
			) {
				investorsDetails.Investor_Signature_File["Can_Delete"] = 1;
			}

			if (
				investorsDetails?.Visa_File?.File_Code &&
				response.data?.Data?.Btn_Save
			) {
				investorsDetails.Visa_File["Can_Delete"] = 1;
			}

			this.setState({
				initValues: investorsDetails,
				investorsDataValidation: investorsDataValidation,
				investorsSystemValidation: investorsSystemValidation,
				investorsDetails: investorsDetails,
				emiratesIDFile: investorsDetails?.EmiratesID_File?.File_Code
					? [investorsDetails?.EmiratesID_File]
					: [],
				emiratesIDFileCode:
					investorsDetails?.EmiratesID_File?.File_Code,
				emiratesIDFile2: investorsDetails?.EmiratesID_File_2?.File_Code
					? [investorsDetails?.EmiratesID_File_2]
					: [],
				emiratesIDFile2Code:
					investorsDetails?.Investor_Details?.EmiratesID_File
						?.File_Code,
				investorSignatureFile: investorsDetails?.Investor_Signature_File
					?.File_Code
					? [investorsDetails?.Investor_Signature_File]
					: [],
				investorSignatureFileCode:
					investorsDetails?.Investor_Details?.Investor_Signature_File
						?.File_Code,
				passportFile: investorsDetails?.Passport_File?.File_Code
					? [investorsDetails?.Passport_File]
					: [],
				passportFileCode:
					investorsDetails?.Investor_Details?.Passport_File
						?.File_Code,
				visaFile: investorsDetails?.Visa_File?.File_Code
					? [investorsDetails?.Visa_File]
					: [],
				visaFileCode:
					investorsDetails?.Investor_Details?.Visa_File?.File_Code,
				Btns: {
					Btn_Delete: response.data?.Data?.Btn_Delete,
					Btn_Print: response.data?.Data?.Btn_Print,
					Btn_Save: response.data?.Data?.Btn_Save,
					Btn_Save_Print: response.data?.Data?.Btn_Save_Print,
				},
			});
		}
	};

	getLicenseInvestorList = async () => {
		let licenseInvestorsArr = [{ label: this.props.t("none"), value: 0 }],
			auth = this.props.currentUser.Data.access_token;

		const response = await apiConfig.post(
			"/API/SYSTEM/DDL/GET_INVESTORS_TYPES",
			{},
			{
				headers: {
					Authorization: `Bearer ${auth}`,
				},
			}
		);

		if (response?.data?.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Record_Name_AR
						: item.Record_Name_EN;

				licenseInvestorsArr.push({
					value: item.Record_Id,
					label,
				});
			});

			this.setState({ licenseInvestorsList: licenseInvestorsArr });
		}
	};

	getVisaTypesList = async () => {
		let visaTypesArr = [{ label: this.props.t("none"), value: 0 }],
			auth = this.props.currentUser.Data.access_token;

		const response = await apiConfig.post(
			"/API/SYSTEM/DDL/GET_VISAS_TYPES",
			{},
			{
				headers: {
					Authorization: `Bearer ${auth}`,
				},
			}
		);

		if (response?.data?.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Record_Name_AR
						: item.Record_Name_EN;

				visaTypesArr.push({
					value: item.Record_Id,
					label,
				});
			});

			this.setState({ visaTypesList: visaTypesArr });
		}
	};

	getInvestorsPrefixsList = async () => {
		let investorsPrefixsArr = [{ label: this.props.t("none"), value: 0 }],
			auth = this.props.currentUser.Data.access_token;

		const response = await apiConfig.post(
			"/API/ADMINISTRATION/LICENSES/COMPANIES/INVESTORS/Get_Investors_Prefixs_List",
			{},
			{
				headers: {
					Authorization: `Bearer ${auth}`,
				},
			}
		);

		if (response?.data?.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Investor_Prefix_Name_AR
						: item.Investor_Prefix_Name_EN;

				investorsPrefixsArr.push({
					value: item.Investor_Prefix_Id,
					label,
				});
			});

			this.setState({ investorsPrefixsList: investorsPrefixsArr });
		}
	};

	getLicenseSignaturesList = async () => {
		let licenseSignaturesArr = [{ label: this.props.t("none"), value: 0 }],
			auth = this.props.currentUser.Data.access_token;

		const response = await apiConfig.post(
			"/API/SYSTEM/DDL/GET_SIGNATURES_TYPES",
			{},
			{
				headers: {
					Authorization: `Bearer ${auth}`,
				},
			}
		);

		if (response?.data?.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Record_Name_AR
						: item.Record_Name_EN;

				licenseSignaturesArr.push({
					value: item.Record_Id,
					label,
				});
			});

			this.setState({ licenseSignaturesList: licenseSignaturesArr });
		}
	};

	getCountriesList = async () => {
		let countriesArr = [{ label: this.props.t("none"), value: 0 }],
			auth = this.props.currentUser.Data.access_token;

		const response = await apiConfig.post(
			"/API/SYSTEM/DDL/GET_COUNTRIES",
			{},
			{
				headers: {
					Authorization: `Bearer ${auth}`,
				},
			}
		);

		if (response?.data?.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Record_Name_AR
						: item.Record_Name_EN;

				countriesArr.push({
					value: item.Record_Id,
					label,
				});
			});

			this.setState({ countriesList: countriesArr });
		}
	};

	getNationalyList = async () => {
		let nationalyArr = [{ label: this.props.t("none"), value: 0 }],
			auth = this.props.currentUser.Data.access_token;

		const response = await apiConfig.post(
			"/API/SYSTEM/DDL/GET_NATIONALITIES",
			{},
			{
				headers: {
					Authorization: `Bearer ${auth}`,
				},
			}
		);

		if (response?.data?.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Record_Name_AR
						: item.Record_Name_EN;

				nationalyArr.push({
					value: item.Record_Id,
					label,
				});
			});

			this.setState({ nationalyList: nationalyArr });
		}
	};

	getEmiratesList = async () => {
		let emiratesArr = [{ label: this.props.t("none"), value: 0 }],
			auth = this.props.currentUser.Data.access_token;

		const response = await apiConfig.post(
			"/API/SYSTEM/DDL/GET_EMIRATES",
			{},
			{
				headers: {
					Authorization: `Bearer ${auth}`,
				},
			}
		);

		if (response?.data?.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Record_Name_AR
						: item.Record_Name_EN;

				emiratesArr.push({
					value: item.Record_Id,
					label,
				});
			});

			this.setState({ emiratesList: emiratesArr });
		}
	};

	onSubmit = async () => {};

	selectFiles = (event, field) => {
		let filesData = [];

		for (const file in event.target.files) {
			if (Object.hasOwnProperty.call(event.target.files, file)) {
				const element = event.target.files[file];
				element["Can_Delete"] = 1;
				filesData.push(element);
			}
		}

		if (field == "investorSignatureFile") {
			this.setState({ investorSignatureFile: filesData });
		} else if (field == "passportFile") {
			this.setState({ passportFile: filesData });
		} else if (field == "visaFile") {
			this.setState({ visaFile: filesData });
		} else if (field == "emiratesIDFile") {
			this.setState({ emiratesIDFile: filesData });
		} else if (field == "emiratesIDFile2") {
			this.setState({ emiratesIDFile2: filesData });
		}
	};

	componentDidMount() {
		Promise.all([
			this.getLicenseInvestorList(),
			this.getVisaTypesList(),
			this.getInvestorsPrefixsList(),
			this.getLicenseSignaturesList(),
			this.getCountriesList(),
			this.getNationalyList(),
			this.getEmiratesList(),
		]).then(() => {
			this.setState({ dataLoaded: true });
		});

		if (this?.props?.licenseInvestorDataId) {
			this.getInvestorDetails();
		}
	}

	// componentDidUpdate() {
	// 	console.log(this.state.investorsDataValidation);
	// }

	addInvestor = async (values, form) => {
		let at = this.props.currentUser.Data.access_token,
			url =
				"/API/ADMINISTRATION/LICENSES/COMPANIES/INVESTORS/Add_Investor";

		const bodyFormData = new FormData();

		let bodyData = {
			...values,
			Company_Id: this.props.companyId,
		};

		bodyFormData.append("PARAMETERS", JSON.stringify(bodyData));

		//if file
		if (
			this.state.investorSignatureFile &&
			this.state.investorSignatureFile.length > 0
		) {
			bodyFormData.append(
				"Investor_Signature_File",
				this.state.investorSignatureFile[0]
			);
		}

		if (this.state.passportFile && this.state.passportFile.length > 0) {
			bodyFormData.append("Passport_File", this.state.passportFile[0]);
		}

		if (this.state.visaFile && this.state.visaFile.length > 0) {
			bodyFormData.append("Visa_File", this.state.visaFile[0]);
		}

		if (this.state.emiratesIDFile && this.state.emiratesIDFile.length > 0) {
			bodyFormData.append(
				"EmiratesID_File",
				this.state.emiratesIDFile[0]
			);
		}

		if (
			this.state.emiratesIDFile2 &&
			this.state.emiratesIDFile2.length > 0
		) {
			bodyFormData.append(
				"EmiratesID_File_2",
				this.state.emiratesIDFile2[0]
			);
		}

		this.props.setLoading(true, "dark");

		await apiConfig
			.post(url, bodyFormData, {
				headers: {
					Authorization: `Bearer ${at}`,
				},
			})
			.then((res) => {
				if (res.data.Status) {
					this.props.setLoading(false);

					navigate(
						`/manage-companies/company-info/investor-info/${res.data.Data.Investor_Details.Company_Id}/${res.data.Data.Investor_Details.License_Investor_Data_Id}`
					);
				} else {
					throw res;
				}
				return res;
			})
			.catch((err) => {
				this.props.setLoading(false);

				if (!err?.data?.Data?.Validation_Result) {
					this.setState({
						investorsDataValidation: err?.data?.Data,
					});
				} else {
					this.props.triggerNotification(
						true,
						err.response ? err.response.data : err.data
					);
				}

				return err;
			});
	};

	updateInvestorDetails = async (values, form, print) => {
		let at = this.props.currentUser.Data.access_token,
			url =
				"/API/ADMINISTRATION/LICENSES/COMPANIES/INVESTORS/Upd_Investor";

		let bodyData = {
			...values,
			...{
				Company_Id: this.props.companyId,
				License_Investor_Data_Id: this.props.licenseInvestorDataId,
				Print_Legal_Files: 0,
			},
		};

		this.props.setLoading(true, "dark");

		//if file
		if (
			this.state.investorSignatureFile &&
			this.state.investorSignatureFile.length > 0 &&
			!this.state.investorSignatureFileCode
		) {
			this.handleUploadFilesOnUpdate(
				"investorSignatureFile",
				"Investor_Signature_File"
			);
		}

		if (
			this.state.passportFile &&
			this.state.passportFile.length > 0 &&
			!this.state.passportFileCode
		) {
			this.handleUploadFilesOnUpdate("passportFile", "Passport_File");
		}

		if (
			this.state.visaFile &&
			this.state.visaFile.length > 0 &&
			!this.state.visaFileCode
		) {
			this.handleUploadFilesOnUpdate("visaFile", "Visa_File");
		}

		if (
			this.state.emiratesIDFile &&
			this.state.emiratesIDFile.length > 0 &&
			!this.state.emiratesIDFileCode
		) {
			this.handleUploadFilesOnUpdate("emiratesIDFile", "EmiratesID_File");
		}

		if (
			this.state.emiratesIDFile2 &&
			this.state.emiratesIDFile2.length > 0 &&
			!this.state.emiratesIDFile2Code
		) {
			this.handleUploadFilesOnUpdate(
				"emiratesIDFile2",
				"EmiratesID_File_2"
			);
		}

		await apiConfig
			.post(url, bodyData, {
				headers: {
					Authorization: `Bearer ${at}`,
				},
			})
			.then((res) => {
				if (res.data.Status) {
					this.props.setLoading(false);
					this.props.triggerNotification(true, res.data);

					this.setState({
						initValues: res?.data?.Data?.Investor_Details,
						investorsDataValidation:
							res?.data?.Data?.Investor_Data_Validation,
						investorsSystemValidation:
							res?.data?.Data?.Investor_System_Validation,
						investorsDetails: res?.data?.Data?.Investor_Details,
						emiratesIDFile: res?.data?.Data?.Investor_Details
							?.EmiratesID_File?.File_Code
							? [
									res?.data?.Data?.Investor_Details
										?.EmiratesID_File,
							  ]
							: [],
						emiratesIDFileCode:
							res?.data?.Data?.Investor_Details?.EmiratesID_File
								?.File_Code,
						emiratesIDFile2: res?.data?.Data?.Investor_Details
							?.EmiratesID_File_2?.File_Code
							? [
									res?.data?.Data?.Investor_Details
										?.EmiratesID_File_2,
							  ]
							: [],
						emiratesIDFile2Code:
							res?.data?.Data?.Investor_Details?.EmiratesID_File_2
								?.File_Code,
						investorSignatureFile: res?.data?.Data?.Investor_Details
							?.Investor_Signature_File?.File_Code
							? [
									res?.data?.Data?.Investor_Details
										?.Investor_Signature_File,
							  ]
							: [],
						investorSignatureFileCode:
							res?.data?.Data?.Investor_Details
								?.Investor_Signature_File?.File_Code,
						passportFile: res?.data?.Data?.Investor_Details
							?.Passport_File?.File_Code
							? [res?.data?.Data?.Investor_Details?.Passport_File]
							: [],
						passportFileCode:
							res?.data?.Data?.Investor_Details?.Passport_File
								?.File_Code,
						visaFile: res?.data?.Data?.Investor_Details.Visa_File
							?.File_Code
							? [res?.data?.Data?.Investor_Details?.Visa_File]
							: [],
						visaFileCode:
							res?.data?.Data?.Investor_Details?.Visa_File
								?.File_Code,
						Btns: {
							Btn_Delete: res.data?.Data?.Btn_Delete,
							Btn_Print: res.data?.Data?.Btn_Print,
							Btn_Save: res.data?.Data?.Btn_Save,
							Btn_Save_Print: res.data?.Data?.Btn_Save_Print,
						},
					});
				} else {
					throw res;
				}
				return res;
			})
			.catch((err) => {
				this.props.setLoading(false);

				if (!err?.data?.Data?.Validation_Result) {
					this.setState({
						investorsDataValidation: err?.data?.Data,
					});
				} else {
					this.props.triggerNotification(
						true,
						err.response ? err.response.data : err.data
					);
				}

				return err;
			});

		// if (print) {
		// 	this.issueLegalFiles();
		// }
	};

	handleUploadFilesOnUpdate = async (fileStateKey, fieldName) => {
		let at = this.props.currentUser.Data.access_token;
		const bodyFormData = new FormData();

		bodyFormData.append(
			"PARAMETERS",
			JSON.stringify({
				Company_Id: this.props.companyId,
				License_Investor_Data_Id: this.props.licenseInvestorDataId,
				File_Input_Name: fieldName,
			})
		);
		bodyFormData.append(fieldName, this.state[fileStateKey][0]);

		const uploadFile = await apiConfig
			.post(
				"/API/ADMINISTRATION/LICENSES/COMPANIES/INVESTORS/Upd_Investor_File",
				bodyFormData,
				{
					headers: {
						Authorization: `Bearer ${at}`,
					},
				}
			)
			.then((res) => {
				if (!res.data.Status) {
					throw res;
				}

				let stateObj = {};

				stateObj[fileStateKey + "Code"] = res?.data?.Data?.File_Code;

				this.setState(stateObj);

				return res;
			})
			.catch((err) => {
				this.setState({
					investorsDataValidation: err?.data?.Data,
				});

				return err;
			});
	};

	deleteInvestor = async () => {
		let at = this.props.currentUser.Data.access_token,
			url =
				"/API/ADMINISTRATION/LICENSES/COMPANIES/INVESTORS/Del_Investor";

		this.props.setLoading(true, "dark");

		await apiConfig
			.post(
				url,
				{
					Company_Id: this.props.companyId,
					License_Investor_Data_Id: this.props.licenseInvestorDataId,
				},
				{
					headers: {
						Authorization: `Bearer ${at}`,
					},
				}
			)
			.then((res) => {
				if (res.data.Status) {
					this.props.setLoading(false);
					this.props.triggerNotification(true, res.data);
					navigate(
						`/manage-companies/company-info/${this.props.companyId}`
					);
				} else {
					throw res;
				}
				return res;
			})
			.catch((err) => {
				this.props.setLoading(false);

				if (!err?.data?.Data?.Validation_Result) {
					this.setState({
						investorsDataValidation: err?.data?.Data,
					});
				} else {
					this.props.triggerNotification(
						true,
						err.response ? err.response.data : err.data
					);
				}

				return err;
			});
	};

	deleteFile = async (fileInputName, File_Code, callBack) => {
		let at = this.props.currentUser.Data.access_token,
			url =
				"/API/ADMINISTRATION/LICENSES/COMPANIES/INVESTORS/Del_Investor_File";

		this.props.setLoading(true, "dark");

		await apiConfig
			.post(
				url,
				{
					Company_Id: this.props.companyId,
					License_Investor_Data_Id: this.props.licenseInvestorDataId,
					File_Input_Name: fileInputName,
					File_Code: File_Code,
				},
				{
					headers: {
						Authorization: `Bearer ${at}`,
					},
				}
			)
			.then((res) => {
				if (res.data.Status) {
					this.props.setLoading(false);
					this.props.triggerNotification(true, res.data);
					callBack();
				} else {
					throw res;
				}
				return res;
			})
			.catch((err) => {
				this.props.setLoading(false);

				this.props.triggerNotification(
					true,
					err.response ? err.response.data : err.data
				);

				return err;
			});
	};

	render() {
		if (!this.state.dataLoaded) {
			return (
				<p
					style={{
						textAlign: "start",
						padding: "10px",
					}}
				>
					{this.props.t("loadingClientData")}
				</p>
			);
		} else {
			return (
				<>
					<Form
						onSubmit={this.onSubmit}
						initialValues={this.state.initValues}
						render={({
							handleSubmit,
							form,
							submitting,
							pristine,
							values,
							handleReset,
						}) => (
							<form
								onSubmit={handleSubmit}
								onReset={handleReset}
								style={{ overflowX: "hidden" }}
								className="py-4 px-3"
								noValidate
							>
								<Row>
									<Col md={12}>
										<div className="d-flex justify-content-between align-items-center mt-2 mb-4">
											<h3>
												{this.props.t(
													"investorMainData"
												)}
											</h3>

											<ButtonSecondary
												variant="outlined"
												endIcon={<ExitToAppIcon />}
												component={Link}
												to={`/manage-companies/company-info/${this.props.companyId}`}
												size="large"
												state={{ tap: 2 }}
											>
												{this.props.t(
													"backToInvistors"
												)}
											</ButtonSecondary>
										</div>

										<Row>
											<Col md={4}>
												<TextField
													disabled
													label={this.props.t(
														"investorSerial"
													)}
													className="read-only mb-3"
													name="License_Investor_Data_Id"
												/>
											</Col>

											<Col md={4}>
												<Field name="License_Investor_Type_Id">
													{(fieldProps) => (
														<Autocomplete
															label={this.props.t(
																"companyRelationType"
															)}
															name="License_Investor_Type_Id"
															options={
																this.state
																	.licenseInvestorsList
															}
															getOptionValue={(
																option
															) => option.value}
															getOptionLabel={(
																option
															) => option.label}
														/>
													)}
												</Field>
											</Col>

											<Col md={4}>
												<Field name="Visa_Type_Id">
													{(fieldProps) => (
														<Autocomplete
															label={this.props.t(
																"residencyType"
															)}
															name="Visa_Type_Id"
															options={
																this.state
																	.visaTypesList
															}
															getOptionValue={(
																option
															) => option.value}
															getOptionLabel={(
																option
															) => option.label}
														/>
													)}
												</Field>
											</Col>

											<Col md={4}>
												<Field name="Investor_Prefix_Id">
													{(fieldProps) => (
														<Autocomplete
															label={this.props.t(
																"prefix"
															)}
															name="Investor_Prefix_Id"
															options={
																this.state
																	.investorsPrefixsList
															}
															getOptionValue={(
																option
															) => option.value}
															getOptionLabel={(
																option
															) => option.label}
														/>
													)}
												</Field>
											</Col>

											<Col md={4}>
												<TextField
													label={this.props.t(
														"investorArabicName"
													)}
													className="mb-3"
													name="Investor_Name_AR"
												/>
											</Col>

											<Col md={4}>
												<TextField
													label={this.props.t(
														"investorEnglishName"
													)}
													className="mb-3"
													name="Investor_Name_EN"
												/>
											</Col>

											<Col md={4}>
												<TextField
													label={this.props.t(
														"partnerRatioInCompany"
													)}
													className="mb-3"
													name="Investor_Partner_Ratio"
													type="number"
													onChange={(e) => {
														form.batch(() => {
															form.change(
																"Investor_Partner_Ratio",
																e.target.value
															);
															form.change(
																"Investor_Shares_Count",
																(values?.Company_Shares_Count /
																	100) *
																	values?.Investor_Partner_Ratio
															);
															form.change(
																"Investor_Shares_Value",
																values?.Investor_Shares_Count *
																	values?.Company_Share_Value
															);
														});
													}}
													onBlur={(e) => {
														form.batch(() => {
															form.change(
																"Investor_Partner_Ratio",
																e.target.value
															);
															form.change(
																"Investor_Shares_Count",
																(values?.Company_Shares_Count /
																	100) *
																	values?.Investor_Partner_Ratio
															);
															form.change(
																"Investor_Shares_Value",
																values?.Investor_Shares_Count *
																	values?.Company_Share_Value
															);
														});
													}}
												/>
												{values.Investor_Partner_Ratio >
													values?.Remaining_Ratio && (
													<p
														style={{
															position:
																"absolute",
															bottom: "-10px",
															color: "red",
														}}
													>
														{this.props.t(
															"value should be less than or equal "
														) +
															values?.Remaining_Ratio}
													</p>
												)}
											</Col>

											<Col md={4}>
												<TextField
													disabled
													label={this.props.t(
														"sharesCount"
													)}
													className="read-only mb-3"
													name="Investor_Shares_Count"
													type="number"
												/>
											</Col>

											<Col md={4}>
												<TextField
													disabled
													label={this.props.t(
														"sharesValue"
													)}
													className="read-only mb-3"
													name="Investor_Shares_Value"
													type="number"
												/>
											</Col>

											<Col md={4}>
												<Field name="License_Signature_Type_Id">
													{(fieldProps) => (
														// <Select
														// 	label={this.props.t(
														// 		"signaturesType"
														// 	)}
														// 	className={`${
														// 		this.props
														// 			.viewMood
														// 			? "read-only"
														// 			: ""
														// 	}`}
														// 	data={
														// 		this.state
														// 			.licenseSignaturesList
														// 	}
														// 	name="License_Signature_Type_Id"
														// 	onChange={(e) => {
														// 		form.change(
														// 			"License_Signature_Type_Id",
														// 			e.target
														// 				.value
														// 		);
														// 	}}
														// ></Select>
														<Autocomplete
															label={this.props.t(
																"signaturesType"
															)}
															name="License_Signature_Type_Id"
															options={
																this.state
																	.licenseSignaturesList
															}
															getOptionValue={(
																option
															) => option.value}
															getOptionLabel={(
																option
															) => option.label}
														/>
													)}
												</Field>
											</Col>

											<Col md={4}>
												{this.state
													.investorSignatureFile &&
												this.state.investorSignatureFile
													.length > 0 ? (
													<div
														style={{
															flexGrow: 1,
															marginInline: "6px",
														}}
													>
														<FilesUpload
															view={true}
															attachments={[
																{
																	Files_Data:
																		[
																			this
																				.state
																				.investorSignatureFile[0],
																		],
																},
															]}
															deleteCB={() => {
																this.deleteFile(
																	"Investor_Signature_File",
																	this.state
																		.investorSignatureFile[0]
																		.File_Code,
																	() => {
																		this.setState(
																			{
																				investorSignatureFile:
																					[],
																			}
																		);
																	}
																);
															}}
															downloadCB={
																downloadAttachment
															}
														/>
													</div>
												) : (
													<ButtonSecondary
														color="primary"
														variant="contained"
														endIcon={
															<CloudUploadIcon />
														}
														style={{
															justifyContent:
																"space-between",
															minWidth: "200px",
														}}
													>
														{this.props.t(
															"investorSignature"
														)}
														<input
															style={{
																position:
																	"absolute",
																width: "100%",
																height: "100%",
																left: 0,
																right: 0,
																top: 0,
																bottom: 0,
																zIndex: 1,
																opacity: 0,
																fontSize: 0,
																cursor: "pointer",
															}}
															type="file"
															onChange={(e) => {
																this.selectFiles(
																	e,
																	"investorSignatureFile"
																);
															}}
															value=""
														/>
													</ButtonSecondary>
												)}
											</Col>
										</Row>
									</Col>
								</Row>

								<Row>
									<Col md={12}>
										<div className="d-flex justify-content-between align-items-center mt-2 mb-4">
											<h3>
												{this.props.t("passportData")}
											</h3>
										</div>

										<Row>
											<Col md={3}>
												<Field name="Home_Country_Id">
													{(fieldProps) => (
														<Autocomplete
															label={this.props.t(
																"investorCountry"
															)}
															name="Home_Country_Id"
															options={
																this.state
																	.countriesList
															}
															getOptionValue={(
																option
															) => option.value}
															getOptionLabel={(
																option
															) => option.label}
														/>
													)}
												</Field>
											</Col>

											<Col md={3}>
												<Field name="Nationality_Id">
													{(fieldProps) => (
														<Autocomplete
															label={this.props.t(
																"nationality"
															)}
															name="Nationality_Id"
															options={
																this.state
																	.nationalyList
															}
															getOptionValue={(
																option
															) => option.value}
															getOptionLabel={(
																option
															) => option.label}
														/>
													)}
												</Field>
											</Col>

											<Col md={3}>
												<Field name="Investor_DOB">
													{(fieldProps) => (
														<>
															{values.Investor_DOB && (
																<label
																	style={{
																		fontSize:
																			"0.8rem",
																		color: "#00b1eb",
																		textAlign:
																			"right",
																		display:
																			"block",
																		right: "0.5rem",
																		position:
																			"relative",
																		marginBottom:
																			"5px",
																		marginTop:
																			"-4px",
																	}}
																>
																	{this.props.t(
																		"holderDOB"
																	)}
																</label>
															)}
															<DateBox
																acceptCustomValue={
																	false
																}
																defaultValue={
																	null
																}
																dateSerializationFormat="yyyy-MM-dd"
																displayFormat="yyyy-MM-dd"
																onValueChanged={(
																	e
																) => {
																	fieldProps.input.onChange(
																		e.value
																	);
																}}
																style={{
																	borderRadius:
																		"50px",
																}}
																type="date"
																placeholder={this.props.t(
																	"holderDOB"
																)}
																className={`${
																	!values.Investor_DOB
																		? "mt-3"
																		: ""
																}`}
																value={
																	values.Investor_DOB
																}
															/>
														</>
													)}
												</Field>
											</Col>

											<Col md={3}>
												<TextField
													label={this.props.t(
														"placeOfBirth"
													)}
													className="mb-3"
													name="Investor_Place_Of_Birth"
												/>
											</Col>

											<Col md={3}>
												<TextField
													label={this.props.t(
														"passportNo"
													)}
													className="mb-3"
													name="Passport_Number"
												/>
											</Col>

											<Col md={3}>
												<Field name="Passport_Country_Id">
													{(fieldProps) => (
														<Autocomplete
															label={this.props.t(
																"passportCountry2"
															)}
															name="Passport_Country_Id"
															options={
																this.state
																	.countriesList
															}
															getOptionValue={(
																option
															) => option.value}
															getOptionLabel={(
																option
															) => option.label}
														/>
													)}
												</Field>
											</Col>

											<Col md={3}>
												<Field name="Passport_Issue_Date">
													{(fieldProps) => (
														<>
															{values.Passport_Issue_Date && (
																<label
																	style={{
																		fontSize:
																			"0.8rem",
																		color: "#00b1eb",
																		textAlign:
																			"right",
																		display:
																			"block",
																		right: "0.5rem",
																		position:
																			"relative",
																		marginBottom:
																			"5px",
																		marginTop:
																			"-4px",
																	}}
																>
																	{this.props.t(
																		"issueDate"
																	)}
																</label>
															)}
															<DateBox
																acceptCustomValue={
																	false
																}
																defaultValue={
																	null
																}
																dateSerializationFormat="yyyy-MM-dd"
																displayFormat="yyyy-MM-dd"
																onValueChanged={(
																	e
																) => {
																	fieldProps.input.onChange(
																		e.value
																	);
																}}
																style={{
																	borderRadius:
																		"50px",
																}}
																type="date"
																placeholder={this.props.t(
																	"issueDate"
																)}
																className={`${
																	!values.Passport_Issue_Date
																		? "mt-3"
																		: ""
																}`}
																value={
																	values.Passport_Issue_Date
																}
															/>
														</>
													)}
												</Field>
											</Col>

											<Col md={3}>
												<Field name="Passport_Expire_Date">
													{(fieldProps) => (
														<>
															{values.Passport_Expire_Date && (
																<label
																	style={{
																		fontSize:
																			"0.8rem",
																		color: "#00b1eb",
																		textAlign:
																			"right",
																		display:
																			"block",
																		right: "0.5rem",
																		position:
																			"relative",
																		marginBottom:
																			"5px",
																		marginTop:
																			"-4px",
																	}}
																>
																	{this.props.t(
																		"expireDate"
																	)}
																</label>
															)}
															<DateBox
																acceptCustomValue={
																	false
																}
																defaultValue={
																	null
																}
																dateSerializationFormat="yyyy-MM-dd"
																displayFormat="yyyy-MM-dd"
																onValueChanged={(
																	e
																) => {
																	fieldProps.input.onChange(
																		e.value
																	);
																}}
																style={{
																	borderRadius:
																		"50px",
																}}
																type="date"
																placeholder={this.props.t(
																	"expireDate"
																)}
																className={`${
																	!values.Passport_Expire_Date
																		? "mt-3"
																		: ""
																}`}
																value={
																	values.Passport_Expire_Date
																}
															/>
														</>
													)}
												</Field>
											</Col>

											<Col md={4}>
												{this.state.passportFile &&
												this.state.passportFile.length >
													0 ? (
													<div
														style={{
															flexGrow: 1,
															marginInline: "6px",
														}}
													>
														<FilesUpload
															view={true}
															attachments={[
																{
																	Files_Data:
																		[
																			this
																				.state
																				.passportFile[0],
																		],
																},
															]}
															deleteCB={() => {
																this.deleteFile(
																	"Passport_File",
																	this.state
																		.passportFile[0]
																		.File_Code,
																	() => {
																		this.setState(
																			{
																				passportFile:
																					[],
																			}
																		);
																	}
																);
															}}
															downloadCB={
																downloadAttachment
															}
														/>
													</div>
												) : (
													<ButtonSecondary
														color="primary"
														variant="contained"
														endIcon={
															<CloudUploadIcon />
														}
														style={{
															justifyContent:
																"space-between",
															minWidth: "200px",
														}}
													>
														{this.props.t(
															"passportCopy"
														)}
														<input
															style={{
																position:
																	"absolute",
																width: "100%",
																height: "100%",
																left: 0,
																right: 0,
																top: 0,
																bottom: 0,
																zIndex: 1,
																opacity: 0,
																fontSize: 0,
																cursor: "pointer",
															}}
															type="file"
															onChange={(e) => {
																this.selectFiles(
																	e,
																	"passportFile"
																);
															}}
															value=""
														/>
													</ButtonSecondary>
												)}
											</Col>
										</Row>
									</Col>
								</Row>

								<Row>
									<Col md={12}>
										<div className="d-flex justify-content-between align-items-center my-4">
											<h3>
												{this.props.t(
													"contactAndAccommodationInformation"
												)}
											</h3>
										</div>

										<Row>
											<Col md={4}>
												<TextField
													label={this.props.t(
														"mobile"
													)}
													className="mb-3"
													name="Investor_Mobile"
												/>
											</Col>

											<Col md={4}>
												<TextField
													label={this.props.t(
														"phone"
													)}
													className="mb-3"
													name="Investor_Phone"
												/>
											</Col>

											<Col md={4}>
												<TextField
													label={this.props.t(
														"email"
													)}
													className="mb-3"
													name="Investor_Mail"
												/>
											</Col>

											{values.Visa_Type_Id && (
												<>
													<Col md={4}>
														<Field name="UAE_Emirate_Id">
															{(fieldProps) => (
																<Autocomplete
																	label={this.props.t(
																		"emirateUAE"
																	)}
																	name="UAE_Emirate_Id"
																	options={
																		this
																			.state
																			.emiratesList
																	}
																	getOptionValue={(
																		option
																	) =>
																		option.value
																	}
																	getOptionLabel={(
																		option
																	) =>
																		option.label
																	}
																/>
															)}
														</Field>
													</Col>

													<Col md={4}>
														<TextField
															label={this.props.t(
																"cityUAE"
															)}
															className="mb-3"
															name="UAE_City"
														/>
													</Col>

													<Col md={4}>
														<TextField
															label={this.props.t(
																"addressUAE"
															)}
															className="mb-3"
															name="UAE_Address"
														/>
													</Col>
												</>
											)}

											{values.Visa_Type_Id !== 4 && (
												<>
													<Col md={4}>
														<TextField
															label={this.props.t(
																"homeCountryCity"
															)}
															className="mb-3"
															name="Home_City_Name"
														/>
													</Col>

													<Col md={8}>
														<TextField
															label={this.props.t(
																"homeCountryDetailAddress"
															)}
															className="mb-3"
															name="Home_Address"
														/>
													</Col>
												</>
											)}
										</Row>
									</Col>
								</Row>

								{(values.Visa_Type_Id === 1 ||
									values.Visa_Type_Id === 2 ||
									values.Visa_Type_Id === 3) && (
									<Row>
										<Col md={12}>
											<div className="d-flex justify-content-between align-items-center mt-2 mb-4">
												<h3>
													{this.props.t(
														"residence/VisaData"
													)}
												</h3>
											</div>
											<Row>
												<Col md={3}>
													<TextField
														label={this.props.t(
															"holderVisaUnifedNumber"
														)}
														className="mb-3"
														name="Visa_Unified_Number"
													/>
												</Col>

												<Col md={3}>
													<Field name="Visa_Issue_Date">
														{(fieldProps) => (
															<>
																{values.Visa_Issue_Date && (
																	<label
																		style={{
																			fontSize:
																				"0.8rem",
																			color: "#00b1eb",
																			textAlign:
																				"right",
																			display:
																				"block",
																			right: "0.5rem",
																			position:
																				"relative",
																			marginBottom:
																				"5px",
																			marginTop:
																				"-4px",
																		}}
																	>
																		{this.props.t(
																			"licenseVisaIssueDate"
																		)}
																	</label>
																)}
																<DateBox
																	acceptCustomValue={
																		false
																	}
																	defaultValue={
																		null
																	}
																	dateSerializationFormat="yyyy-MM-dd"
																	displayFormat="yyyy-MM-dd"
																	onValueChanged={(
																		e
																	) => {
																		fieldProps.input.onChange(
																			e.value
																		);
																	}}
																	style={{
																		borderRadius:
																			"50px",
																	}}
																	type="date"
																	placeholder={this.props.t(
																		"Visa_Issue_Date"
																	)}
																	className={`${
																		!values.Visa_Issue_Date
																			? "mt-3"
																			: ""
																	}`}
																	value={
																		values.Visa_Issue_Date
																	}
																/>
															</>
														)}
													</Field>
												</Col>

												<Col md={3}>
													<Field name="Visa_Expire_Date">
														{(fieldProps) => (
															<>
																{values.Visa_Expire_Date && (
																	<label
																		style={{
																			fontSize:
																				"0.8rem",
																			color: "#00b1eb",
																			textAlign:
																				"right",
																			display:
																				"block",
																			right: "0.5rem",
																			position:
																				"relative",
																			marginBottom:
																				"5px",
																			marginTop:
																				"-4px",
																		}}
																	>
																		{this.props.t(
																			"licenseVisaExpireDate"
																		)}
																	</label>
																)}
																<DateBox
																	acceptCustomValue={
																		false
																	}
																	defaultValue={
																		null
																	}
																	dateSerializationFormat="yyyy-MM-dd"
																	displayFormat="yyyy-MM-dd"
																	onValueChanged={(
																		e
																	) => {
																		fieldProps.input.onChange(
																			e.value
																		);
																	}}
																	style={{
																		borderRadius:
																			"50px",
																	}}
																	type="date"
																	placeholder={this.props.t(
																		"Visa_Expire_Date"
																	)}
																	className={`${
																		!values.Visa_Expire_Date
																			? "mt-3"
																			: ""
																	}`}
																	value={
																		values.Visa_Expire_Date
																	}
																/>
															</>
														)}
													</Field>
												</Col>

												<Col md={3}>
													<TextField
														label={this.props.t(
															"Visa_Sponsor_Name"
														)}
														className="mb-3"
														name="Visa_Sponsor_Name"
													/>
												</Col>

												<Col md={4}>
													{this.state.visaFile &&
													this.state.visaFile.length >
														0 ? (
														<div
															style={{
																flexGrow: 1,
																marginInline:
																	"6px",
															}}
														>
															<FilesUpload
																view={true}
																attachments={[
																	{
																		Files_Data:
																			[
																				this
																					.state
																					.visaFile[0],
																			],
																	},
																]}
																deleteCB={() => {
																	this.deleteFile(
																		"Visa_File",
																		this
																			.state
																			.visaFile[0]
																			.File_Code,
																		() => {
																			this.setState(
																				{
																					visaFile:
																						[],
																				}
																			);
																		}
																	);
																}}
																downloadCB={
																	downloadAttachment
																}
															/>
														</div>
													) : (
														<ButtonSecondary
															color="primary"
															variant="contained"
															endIcon={
																<CloudUploadIcon />
															}
															style={{
																justifyContent:
																	"space-between",
																minWidth:
																	"200px",
															}}
														>
															{this.props.t(
																"issuedVisaCopy"
															)}
															<input
																style={{
																	position:
																		"absolute",
																	width: "100%",
																	height: "100%",
																	left: 0,
																	right: 0,
																	top: 0,
																	bottom: 0,
																	zIndex: 1,
																	opacity: 0,
																	fontSize: 0,
																	cursor: "pointer",
																}}
																type="file"
																onChange={(
																	e
																) => {
																	this.selectFiles(
																		e,
																		"visaFile"
																	);
																}}
																value=""
															/>
														</ButtonSecondary>
													)}
												</Col>
											</Row>
										</Col>
									</Row>
								)}

								{(values.Visa_Type_Id === 1 ||
									values.Visa_Type_Id === 3 ||
									values.Visa_Type_Id === 4) && (
									<Row className="mt-4">
										<Col md={12}>
											<div className="d-flex justify-content-between align-items-center mt-2 mb-4">
												<h3>
													{this.props.t(
														"identityData"
													)}
												</h3>
											</div>

											<Row>
												<Col md={4}>
													<Field name="Holder_EmiratesID_Number">
														{(fieldProps) => (
															<PatternFormat
																format="784-####-#######-#"
																mask="_"
																customInput={
																	TextField
																}
																label={this.props.t(
																	"EmiratesID_Number"
																)}
																className={`${
																	this.state
																		.lockHolderData
																		? "read-only"
																		: ""
																} mb-3`}
																name="EmiratesID_Number"
																onValueChange={(
																	values,
																	source
																) => {
																	fieldProps.input.onChange(
																		values.formattedValue
																	);
																}}
																value={
																	values.EmiratesID_Number
																}
																style={{
																	direction:
																		"ltr",
																}}
															/>
														)}
													</Field>
													{/* <TextField
														label={this.props.t(
															"EmiratesID_Number"
														)}
														className="mb-3"
														name="EmiratesID_Number"
													/> */}
												</Col>

												<Col md={4}>
													<Field name="EmiratesID_Issue_Date">
														{(fieldProps) => (
															<>
																{values.EmiratesID_Issue_Date && (
																	<label
																		style={{
																			fontSize:
																				"0.8rem",
																			color: "#00b1eb",
																			textAlign:
																				"right",
																			display:
																				"block",
																			right: "0.5rem",
																			position:
																				"relative",
																			marginBottom:
																				"5px",
																			marginTop:
																				"-4px",
																		}}
																	>
																		{this.props.t(
																			"EmiratesID_Issue_Date"
																		)}
																	</label>
																)}
																<DateBox
																	acceptCustomValue={
																		false
																	}
																	defaultValue={
																		null
																	}
																	dateSerializationFormat="yyyy-MM-dd"
																	displayFormat="yyyy-MM-dd"
																	onValueChanged={(
																		e
																	) => {
																		fieldProps.input.onChange(
																			e.value
																		);
																	}}
																	style={{
																		borderRadius:
																			"50px",
																	}}
																	type="date"
																	placeholder={this.props.t(
																		"EmiratesID_Issue_Date"
																	)}
																	className={`${
																		!values.EmiratesID_Issue_Date
																			? "mt-3"
																			: ""
																	}`}
																	value={
																		values.EmiratesID_Issue_Date
																	}
																/>
															</>
														)}
													</Field>
												</Col>

												<Col md={4}>
													<Field name="EmiratesID_Expire_Date">
														{(fieldProps) => (
															<>
																{values.EmiratesID_Expire_Date && (
																	<label
																		style={{
																			fontSize:
																				"0.8rem",
																			color: "#00b1eb",
																			textAlign:
																				"right",
																			display:
																				"block",
																			right: "0.5rem",
																			position:
																				"relative",
																			marginBottom:
																				"5px",
																			marginTop:
																				"-4px",
																		}}
																	>
																		{this.props.t(
																			"EmiratesID_Expire_Date"
																		)}
																	</label>
																)}
																<DateBox
																	acceptCustomValue={
																		false
																	}
																	defaultValue={
																		null
																	}
																	dateSerializationFormat="yyyy-MM-dd"
																	displayFormat="yyyy-MM-dd"
																	onValueChanged={(
																		e
																	) => {
																		fieldProps.input.onChange(
																			e.value
																		);
																	}}
																	style={{
																		borderRadius:
																			"50px",
																	}}
																	type="date"
																	placeholder={this.props.t(
																		"EmiratesID_Expire_Date"
																	)}
																	className={`${
																		!values.EmiratesID_Expire_Date
																			? "mt-3"
																			: ""
																	}`}
																	value={
																		values.EmiratesID_Expire_Date
																	}
																/>
															</>
														)}
													</Field>
												</Col>

												<Col md={6}>
													{this.state
														.emiratesIDFile &&
													this.state.emiratesIDFile
														.length > 0 ? (
														<div
															style={{
																flexGrow: 1,
																marginInline:
																	"6px",
															}}
														>
															<FilesUpload
																view={true}
																attachments={[
																	{
																		Files_Data:
																			[
																				this
																					.state
																					.emiratesIDFile[0],
																			],
																	},
																]}
																deleteCB={() => {
																	this.deleteFile(
																		"EmiratesID_File",
																		this
																			.state
																			.emiratesIDFile[0]
																			.File_Code,
																		() => {
																			this.setState(
																				{
																					emiratesIDFile:
																						[],
																				}
																			);
																		}
																	);
																}}
																downloadCB={
																	downloadAttachment
																}
															/>
														</div>
													) : (
														<ButtonSecondary
															color="primary"
															variant="contained"
															endIcon={
																<CloudUploadIcon />
															}
															style={{
																justifyContent:
																	"space-between",
																minWidth:
																	"200px",
															}}
														>
															{this.props.t(
																"idFrontCopy"
															)}
															<input
																style={{
																	position:
																		"absolute",
																	width: "100%",
																	height: "100%",
																	left: 0,
																	right: 0,
																	top: 0,
																	bottom: 0,
																	zIndex: 1,
																	opacity: 0,
																	fontSize: 0,
																	cursor: "pointer",
																}}
																type="file"
																onChange={(
																	e
																) => {
																	this.selectFiles(
																		e,
																		"emiratesIDFile"
																	);
																}}
																value=""
															/>
														</ButtonSecondary>
													)}
												</Col>

												<Col md={6}>
													{this.state
														.emiratesIDFile2 &&
													this.state.emiratesIDFile2
														.length > 0 ? (
														<div
															style={{
																flexGrow: 1,
																marginInline:
																	"6px",
															}}
														>
															<FilesUpload
																view={true}
																attachments={[
																	{
																		Files_Data:
																			[
																				this
																					.state
																					.emiratesIDFile2[0],
																			],
																	},
																]}
																deleteCB={() => {
																	this.deleteFile(
																		"EmiratesID_File_2",
																		this
																			.state
																			.emiratesIDFile2[0]
																			.File_Code,
																		() => {
																			this.setState(
																				{
																					emiratesIDFile2:
																						[],
																				}
																			);
																		}
																	);
																}}
																downloadCB={
																	downloadAttachment
																}
															/>
														</div>
													) : (
														<ButtonSecondary
															color="primary"
															variant="contained"
															endIcon={
																<CloudUploadIcon />
															}
															style={{
																justifyContent:
																	"space-between",
																minWidth:
																	"200px",
															}}
														>
															{this.props.t(
																"idBackCopy"
															)}
															<input
																style={{
																	position:
																		"absolute",
																	width: "100%",
																	height: "100%",
																	left: 0,
																	right: 0,
																	top: 0,
																	bottom: 0,
																	zIndex: 1,
																	opacity: 0,
																	fontSize: 0,
																	cursor: "pointer",
																}}
																type="file"
																onChange={(
																	e
																) => {
																	this.selectFiles(
																		e,
																		"emiratesIDFile2"
																	);
																}}
																value=""
															/>
														</ButtonSecondary>
													)}
												</Col>
											</Row>
										</Col>
									</Row>
								)}

								{!this.state.investorsSystemValidation
									?.Is_Valid &&
									this.state.investorsSystemValidation
										?.Validation_Errors_List?.length >
										0 && (
										<Row className="mt-5">
											<Col xs={12}>
												<label
													style={{
														fontSize: "1.2rem",
														color: "#00b1eb",
														textAlign: "right",
														display: "block",
														right: "0.5rem",
														position: "relative",
														marginBottom: "5px",
														marginTop: "-4px",
													}}
												>
													{this.props.t(
														"investorDataErrors"
													)}
												</label>
											</Col>
											<Col
												xs={12}
												style={{
													border: "4px solid red",
													borderRadius: "4px",
													paddingBlock: "18px",
												}}
											>
												<Row>
													{this.state.investorsSystemValidation?.Validation_Errors_List?.map(
														(error) => {
															return (
																<Col md={3}>
																	<h5>
																		{this
																			.props
																			.i18n
																			.language ===
																		"ar"
																			? error.Error_Source_AR
																			: error.Error_Source_EN}
																	</h5>

																	<p>
																		{this
																			.props
																			.i18n
																			.language ===
																		"ar"
																			? error.Error_Message_EN
																			: error.Error_Message_EN}
																	</p>
																</Col>
															);
														}
													)}
												</Row>
											</Col>
										</Row>
									)}

								<div className="d-flex justify-content-center align-itmes-center my-5 mt-4">
									{this.props.isAddForm && (
										<ButtonPrimary
											className="mx-2"
											color="primary"
											variant="contained"
											onClick={() => {
												this.addInvestor(values, form);
											}}
										>
											{this.props.t("addInvestor")}
										</ButtonPrimary>
									)}

									{!!this.state.Btns?.Btn_Save && (
										<ButtonPrimary
											disabled={
												values.Investor_Partner_Ratio >
												values?.Remaining_Ratio
											}
											className="mx-2"
											color="primary"
											variant="contained"
											onClick={() => {
												this.updateInvestorDetails(
													values,
													form,
													false
												);
											}}
										>
											{this.props.t("save")}
										</ButtonPrimary>
									)}

									{!!this.state.Btns?.Btn_Save_Print && (
										<ButtonSecondary
											className="mx-2"
											color="primary"
											variant="contained"
											onClick={() => {
												this.updateInvestorDetails(
													values,
													form,
													true
												);
											}}
										>
											{this.props.t("saveAndIssue")}
										</ButtonSecondary>
									)}

									{!!this.state.Btns?.Btn_Print && (
										<ButtonSecondary
											className="mx-2"
											color="primary"
											variant="contained"
											onClick={() => {
												this.issueLegalFiles();
											}}
										>
											{this.props.t("issueLegalFiles")}
										</ButtonSecondary>
									)}

									{!!this.state.Btns?.Btn_Delete && (
										<ButtonDanger
											className="mx-2"
											color="primary"
											variant="contained"
											onClick={() => {
												this.deleteInvestor();
											}}
										>
											{this.props.t("deleteInvestorData")}
										</ButtonDanger>
									)}
								</div>

								{!this.state.investorsDataValidation
									?.Validation_Result &&
									this.state.investorsDataValidation
										?.Validation_Results_List?.length >
										0 && (
										<Row>
											<Col
												xs={12}
												style={{
													border: "4px solid red",
													borderRadius: "4px",
													paddingBlock: "18px",
													color: "red",
												}}
											>
												<Row>
													{this.state.investorsDataValidation?.Validation_Results_List?.map(
														(error) => {
															if (!error.Result) {
																return (
																	<Col md={3}>
																		<h5>
																			{this
																				.props
																				.i18n
																				.language ===
																			"ar"
																				? error.Title_Name_AR
																				: error.Title_Name_EN}
																		</h5>

																		<p>
																			{this
																				.props
																				.i18n
																				.language ===
																			"ar"
																				? error.Error_AR
																				: error.Error_EN}
																		</p>
																	</Col>
																);
															}
														}
													)}
												</Row>
											</Col>
										</Row>
									)}
							</form>
						)}
					/>
				</>
			);
		}
	}
}

const mapStateToProps = (state) => {
	return { currentUser: state.currentUser, reload: state.reload };
};

export default connect(mapStateToProps, {
	triggerNotification,
	setLoading,
	triggerDialogForm,
	triggerDialog,
})(translationHook(InvestorInfo));
